import React, { useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addBlog } from "core/redux/actions/newBlogAction";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { tagsData } from "app/modules/ClientModules/const";
import Checkbox from "@mui/material/Checkbox";
import {
  Paper,
  Typography,
  TextField,
  Button,
  Box,
  Switch,
  FormControlLabel,
  FormGroup,
  CircularProgress,
  Alert,
} from "@mui/material";

function AddPost() {
  const [title, setTitle] = useState("");
  const [imgBackground, setImgBackground] = useState("");
  const [id, setId] = useState(0);
  const [content, setContent] = useState("");
  const [tags, setTags] = useState([]);
  const [standOut, setStandOut] = useState(false); // ✅ Thêm state cho standOut
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.blog);
  const quillRef = useRef(null);

  const imageHandler = useCallback(() => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      const range = quill.getSelection() || { index: 0 };
      const imageUrl = prompt("Nhập URL ảnh:");
      if (imageUrl) {
        quill.insertEmbed(range.index, "image", imageUrl);
      }
    } else {
      console.error("Quill editor not initialized.");
    }
  }, []);

  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, false] }],
        [{ font: [] }, { size: [] }],
        ["bold", "italic", "underline"],
        [{ color: [] }, { background: [] }],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image"],
        [{ align: [] }],
      ],
      handlers: { image: imageHandler },
    },
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!title || !imgBackground || !content) {
      alert("Vui lòng nhập đầy đủ thông tin!");
      return;
    }
    const blogData = {
      title,
      imgBackground,
      // id,
      tags: tags.map((index) => index),
      content,
      standOut, // ✅ Thêm standOut vào dữ liệu gửi đi
    };
    // console.log(blogData);
    dispatch(addBlog(blogData));
  };

  return (
    <Paper
      elevation={3}
      sx={{
        p: 4,
        maxWidth: "800px",
        mx: "auto",
        my: 4,
        borderRadius: 2,
      }}
    >
      <Typography variant="h4" gutterBottom align="center" sx={{ mb: 3 }}>
        Thêm Bài Viết
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Box component="form" onSubmit={handleSubmit} sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
        {/* Tiêu đề */}
        <TextField
          label="Tiêu đề"
          variant="outlined"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
          fullWidth
          placeholder="Nhập tiêu đề bài viết"
        />

        {/* Hình bìa */}
        <TextField
          label="Link hình bìa"
          variant="outlined"
          value={imgBackground}
          onChange={(e) => setImgBackground(e.target.value)}
          required
          fullWidth
          placeholder="Dán URL hình bìa"
        />

        {/* ID */}
        {/* <TextField
          label="ID"
          variant="outlined"
          type="number"
          value={id}
          onChange={(e) => setId(e.target.value)}
          required
          fullWidth
          placeholder="Nhập ID"
        /> */}

        {/* Tags */}
        <Box>
          <Typography variant="subtitle1" gutterBottom>
            Gắn thẻ
          </Typography>
          <FormGroup row sx={{ gap: 2 }}>
            {tagsData.map((tag, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={tags.includes(index)}
                    onChange={(e) =>
                      setTags((prevTags) =>
                        e.target.checked ? [...prevTags, index] : prevTags.filter((t) => t !== index)
                      )
                    }
                  />
                }
                label={tag}
              />
            ))}
          </FormGroup>
        </Box>

        {/* Toggle StandOut */}
        <FormControlLabel
          control={
            <Switch
              checked={standOut}
              onChange={(e) => setStandOut(e.target.checked)} // ✅ Toggle true/false
            />
          }
          label="Đánh dấu nổi bật"
        />

        {/* Nội dung */}
        <Box>
          <Typography variant="subtitle1" gutterBottom>
            Nội dung
          </Typography>
          <Box
            sx={{
              "& .ql-toolbar": {
                position: "sticky",
                top: 0,
                zIndex: 1000,
                backgroundColor: "#fff",
                borderBottom: "1px solid #ccc",
              },
              "& .ql-container": {
                height: "400px",
                overflowY: "auto",
              },
            }}
          >
            <ReactQuill
              ref={quillRef}
              value={content}
              onChange={(value) => setContent(value)}
              modules={modules}
            />
          </Box>
        </Box>

        {/* Nút submit */}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading}
          sx={{ mt: 2, py: 1.5, fontSize: "1rem" }}
          startIcon={loading && <CircularProgress size={20} color="inherit" />}
        >
          {loading ? "Đang gửi..." : "Hoàn thành"}
        </Button>
      </Box>
    </Paper>
  );
}

export default AddPost;