import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllBlogs, deleteBlog } from "core/redux/actions/newBlogAction";
import { Link } from "react-router-dom";
import "./styles/styles.scss";
import {
    Paper,
    Typography,
    Box,
    Button,
    CircularProgress,
} from "@mui/material";

function FixPost() {
    const dispatch = useDispatch();
    const blogs = useSelector((state) => state.newblog.blogs);
    const [filteredBlogs, setFilteredBlogs] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [sortOption, setSortOption] = useState("date");
    const [loading, setLoading] = useState(true);

    // Fetch all blogs when the component mounts
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getAllBlogs());
    }, [dispatch]);

    // Update filtered blogs based on fetched blogs
    useEffect(() => {
        if (blogs && blogs.length > 0) {
            setFilteredBlogs(blogs);
            setLoading(false);
        }
        setFilteredBlogs(blogs);
    }, [blogs]);

    // Handle search filtering (chỉ tìm theo title)
    const handleSearch = (e) => {
        const query = e.target.value;
        setSearchQuery(query);

        const filtered = blogs.filter((blog) =>
            blog.title.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredBlogs(filtered);
    };

    // Handle sorting
    const handleSort = (e) => {
        const option = e.target.value;
        setSortOption(option);

        let sortedBlogs = [...filteredBlogs];

        if (option === "date") {
            sortedBlogs.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        } else if (option === "title") {
            sortedBlogs.sort((a, b) => a.title.localeCompare(b.title));
        } else if (option === "tags") {
            sortedBlogs.sort((a, b) => a.tags.length - b.tags.length);
        }

        setFilteredBlogs(sortedBlogs);
    };

    // Handle blog deletion
    const handleDelete = (blogId) => {
        if (window.confirm("Are you sure you want to delete this post?")) {
            dispatch(deleteBlog(blogId));
            setFilteredBlogs((prevBlogs) => prevBlogs.filter(blog => blog.id !== blogId));
        }
    };


    if (loading) {
        return (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Paper elevation={3} sx={{ p: 4, maxWidth: "1200px", mx: "auto", my: 4 }}>
            <Typography variant="h4" gutterBottom align="center">
                Chỉnh Sửa Bài Viết
            </Typography>

            {/* Search and Sort */}
            <Box sx={{ display: "flex", gap: 2, mb: 4, justifyContent: "center" }}>
                <input
                    type="text"
                    value={searchQuery}
                    onChange={handleSearch}
                    placeholder="Tìm kiếm bài viết theo tiêu đề..."
                    style={{
                        padding: "10px",
                        width: "300px",
                        borderRadius: "4px",
                        border: "1px solid #ccc",
                    }}
                />
                <select
                    onChange={handleSort}
                    value={sortOption}
                    style={{ padding: "10px", borderRadius: "4px" }}
                >
                    <option value="date">Sắp xếp theo ngày</option>
                    <option value="title">Sắp xếp theo tên</option>
                    <option value="tags">Sắp xếp theo tags</option>
                </select>
            </Box>

            {/* Display filtered and sorted blogs */}
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 3 }}>
                {filteredBlogs.length > 0 ? (
                    filteredBlogs.map((blog) => (
                        <Paper
                            key={blog._id || blog.id}
                            elevation={2}
                            sx={{
                                width: { xs: "100%", sm: "48%", md: "31%" },
                                p: 2,
                                borderRadius: 2,
                                display: "flex",
                                flexDirection: "column",
                                gap: 2,
                            }}
                        >
                            <img
                                src={blog.imgBackground}
                                alt={blog.title}
                                style={{
                                    width: "100%",
                                    height: "150px",
                                    objectFit: "cover",
                                    borderRadius: "4px",
                                }}
                            // onError={(e) => (e.target.src = "https://via.placeholder.com/150")} // Xử lý lỗi ảnh
                            />
                            <Box>
                                <Typography variant="h6">{blog.title}</Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {new Date(blog.createdAt).toLocaleDateString()}
                                </Typography>
                                {/* Không hiển thị content */}
                            </Box>
                            <Box sx={{ display: "flex", gap: 2, mt: "auto" }}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    component={Link}
                                    to={`/admin/chinh-sua-bai-viet-chi-tiet/${blog.id}`}
                                >
                                    Chỉnh sửa
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="error"
                                    onClick={() => handleDelete(blog.id)}
                                >
                                    Xóa
                                </Button>
                            </Box>
                        </Paper>
                    ))
                ) : (
                    <Typography variant="body1" sx={{ mx: "auto" }}>
                        Không tìm thấy blog
                    </Typography>
                )}
            </Box>
        </Paper>
    );
}

export default FixPost;