// Action Types
export const GET_COMMENTS_REQUEST = "GET_COMMENTS_REQUEST"
export const GET_COMMENTS_SUCCESS = "GET_COMMENTS_SUCCESS"
export const GET_COMMENTS_FAILED = "GET_COMMENTS_FAILED"

export const ADD_COMMENT_REQUEST = "ADD_COMMENT_REQUEST"
export const ADD_COMMENT_SUCCESS = "ADD_COMMENT_SUCCESS"
export const ADD_COMMENT_FAILED = "ADD_COMMENT_FAILED"

export const DELETE_COMMENT_REQUEST = "DELETE_COMMENT_REQUEST"
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS"
export const DELETE_COMMENT_FAILED = "DELETE_COMMENT_FAILED"

export const UPDATE_COMMENT_REQUEST = "UPDATE_COMMENT_REQUEST"
export const UPDATE_COMMENT_SUCCESS = "UPDATE_COMMENT_SUCCESS"
export const UPDATE_COMMENT_FAILED = "UPDATE_COMMENT_FAILED"