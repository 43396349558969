import React, { useState, useRef, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSpecificBlog, updateBlog } from "core/redux/actions/newBlogAction";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { tagsData } from "app/modules/ClientModules/const";
import { useParams } from "react-router-dom";
import {
  Paper,
  Typography,
  TextField,
  Button,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  CircularProgress,
  Alert,
} from "@mui/material";

function EditPost() {
  const dispatch = useDispatch();
  const { blogId } = useParams(); // Lấy blogId từ URL
  const { loading, error, blog } = useSelector((state) => state.blog); // Lấy trạng thái từ Redux
  const quillRef = useRef(null);

  // State cho form
  const [title, setTitle] = useState("");
  const [imgBackground, setImgBackground] = useState("");
  const [id, setId] = useState(0);
  const [content, setContent] = useState("");
  const [tags, setTags] = useState([]);

  // Dữ liệu mặc định nếu không fetch được từ API
  const initialContent = `
    <p>Không ai sinh ra là một cá thể tách biệt khỏi vũ trụ, mỗi chúng ta đều đang góp phần vào thế giới xung quanh bằng nhiều cách khác nhau. Ví như khi bạn cười với một người, thì cũng đã phần nào chạm vào cảm xúc họ. Hoặc khi bạn là lãnh đạo, cấp dưới sẽ bị ảnh hưởng cách làm việc từ bạn. Nhưng điều gì đã khiến một vài người suy nghĩ mình chẳng có sức ảnh hưởng trong cuộc đời?</p>
    <p>Ai cũng muốn mình hoàn thiện để cống hiến cho đời, nhưng điều đó khiến một số người chỉ nhìn vào thiếu sót để nâng cấp mình. Khi đó, họ sẽ nghĩ việc thất bại vì mình kém cỏi, thiếu kiến thức hoặc không được sinh ra để làm điều mình thích. Đến cuối cùng, họ chọn cách từ bỏ lý tưởng cuộc sống. Nếu những suy nghĩ này không giúp bạn nhìn thấy giá trị bản thân, đã đến lúc nên thay đổi chúng.</p>
    <img src="https://lpe.vn/api/public/images/blog/1643508516835_253683834_3084477171829121_6930707038994392072_n.jpg" alt="Blog Image" />
    <p>Thay vì trách cứ và phán xét bản thân và làm mình chùn bước, hãy cho mình góc nhìn tích cực. Mỗi ngày, bạn có thể lưu lại những điều tốt đẹp mà bản thân đã làm, có thể là việc bảo vệ môi người, tạo ra niềm vui cho người khác. Chỉ cần sống tích cực mỗi ngày, thì bạn đã và đang tạo nên điều đẹp đẽ cho đời. Từ đó, hãy bước dần mình đến những điều lớn lao, tạo nên những đóng góp mạnh mẽ hơn.</p>
  `;

  // Fetch dữ liệu bài viết khi component mount
  useEffect(() => {
    if (blogId) {
      console.log("Fetching blog with ID:", blogId); // Debug
      dispatch(getSpecificBlog(blogId));
    }
  }, [dispatch, blogId]);

  // Cập nhật state khi nhận được dữ liệu từ Redux
  useEffect(() => {
    if (blog) {
      console.log("Blog data received:", blog); // Debug
      setTitle(blog.title || "");
      setImgBackground(blog.imgBackground || ""); // Nếu imgBackground không phải URL, để trống hoặc xử lý
      setId(blog.id || 0);
      setContent(blog.content || initialContent);
      setTags(blog.tags ? blog.tags.map((tag) => tagsData.indexOf(tag)).filter((index) => index !== -1) : []);
    }
  }, [blog]);

  const imageHandler = useCallback(() => {
    const quill = quillRef.current.getEditor();
    const range = quill.getSelection();
    const imageUrl = prompt("Nhập URL ảnh:");
    if (imageUrl) {
      quill.insertEmbed(range.index, "image", imageUrl);
    }
  }, []);

  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, false] }],
        [{ font: [] }, { size: [] }],
        ["bold", "italic", "underline"],
        [{ color: [] }, { background: [] }],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image"],
        [{ align: [] }],
      ],
      handlers: { image: imageHandler },
    },
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!title || !imgBackground || !content) {
      alert("Vui lòng nhập đầy đủ thông tin!");
      return;
    }
    const blogData = {
      title,
      imgBackground,
      id,
      tags: tags.map((index) => tagsData[index]),
      content,
    };
    console.log("Submitting updated blog data:", blogData); // Debug
    dispatch(updateBlog(blogId, blogData));
  };

  // Hiển thị lỗi nếu không fetch được dữ liệu
  if (loading && !blog) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error && !blog) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <Paper
      elevation={3}
      sx={{
        p: 4,
        maxWidth: "800px",
        mx: "auto",
        my: 4,
        borderRadius: 2,
      }}
    >
      <Typography variant="h4" gutterBottom align="center" sx={{ mb: 3 }}>
        Chỉnh Sửa Bài Viết
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Box component="form" onSubmit={handleSubmit} sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
        {/* Tiêu đề */}
        <TextField
          label="Tiêu đề"
          variant="outlined"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
          fullWidth
          placeholder="Nhập tiêu đề bài viết"
        />

        {/* Hình bìa */}
        <TextField
          label="Link hình bìa"
          variant="outlined"
          value={imgBackground}
          onChange={(e) => setImgBackground(e.target.value)}
          required
          fullWidth
          placeholder="Dán URL hình bìa"
        />

        {/* ID */}
        <TextField
          label="ID"
          variant="outlined"
          type="number"
          value={id}
          onChange={(e) => setId(e.target.value)}
          required
          fullWidth
          placeholder="Nhập ID"
        />

        {/* Tags */}
        <Box>
          <Typography variant="subtitle1" gutterBottom>
            Gắn thẻ
          </Typography>
          <FormGroup row sx={{ gap: 2 }}>
            {tagsData.map((tag, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={tags.includes(index)}
                    onChange={(e) =>
                      setTags((prevTags) =>
                        e.target.checked ? [...prevTags, index] : prevTags.filter((t) => t !== index)
                      )
                    }
                  />
                }
                label={tag}
              />
            ))}
          </FormGroup>
        </Box>

        {/* Nội dung */}
        <Box>
          <Typography variant="subtitle1" gutterBottom>
            Nội dung
          </Typography>
          <ReactQuill
            ref={quillRef}
            value={content}
            onChange={(value) => setContent(value)}
            modules={modules}
            style={{ height: "300px", marginBottom: "40px" }}
          />
        </Box>

        {/* Nút submit */}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading}
          sx={{ mt: 2, py: 1.5, fontSize: "1rem" }}
          startIcon={loading && <CircularProgress size={20} color="inherit" />}
        >
          {loading ? "Đang gửi..." : "Xác nhận"}
        </Button>
      </Box>
    </Paper>
  );
}

export default EditPost;