import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Container, Paper } from '@mui/material';
import AdminChat from './AdminChat';

const ChatPage = () => {
    const { clientId } = useParams();
    const navigate = useNavigate();

    const handleBack = () => {
        navigate('/admin/bang-dieu-khien-tin-nhan');
    };

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ height: 'calc(100vh - 200px)', minHeight: '600px' }}>
                    <AdminChat clientId={clientId} onBack={handleBack} />
                </Box>
            </Paper>
        </Container>
    );
};

export default ChatPage; 