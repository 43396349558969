import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Paper,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
    CircularProgress,
    Divider
} from '@mui/material';
import { database } from '../../../../firebase';
import { ref, get } from 'firebase/database';
import { format } from 'date-fns';
import PersonIcon from '@mui/icons-material/Person';
import { useNavigate } from 'react-router-dom';

const ChatDashboard = () => {
    const [chatClients, setChatClients] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchChatClients = async () => {
            try {
                setLoading(true);
                const chatsRef = ref(database, 'chats');
                const snapshot = await get(chatsRef);

                if (!snapshot.exists()) {
                    setChatClients([]);
                    setLoading(false);
                    return;
                }

                const chatRooms = snapshot.val();
                const clientsArray = Object.entries(chatRooms).map(([clientId, chatData]) => {
                    // Get the last message if available
                    let lastMessage = null;
                    let lastTimestamp = null;

                    if (chatData.messages) {
                        const messagesArray = Object.values(chatData.messages);
                        const lastMessageObj = messagesArray[messagesArray.length - 1];
                        lastMessage = lastMessageObj.message;
                        lastTimestamp = lastMessageObj.timestamp;
                    }

                    // Get client status
                    let isOnline = false;
                    if (chatData.status && chatData.status.client) {
                        isOnline = chatData.status.client.online === true;
                    }

                    return {
                        clientId,
                        lastMessage,
                        lastTimestamp,
                        isOnline
                    };
                });

                // Sort by timestamp (newest first)
                clientsArray.sort((a, b) => {
                    if (!a.lastTimestamp) return 1;
                    if (!b.lastTimestamp) return -1;
                    return new Date(b.lastTimestamp) - new Date(a.lastTimestamp);
                });

                // Limit to 50 clients
                setChatClients(clientsArray.slice(0, 50));
                setLoading(false);
            } catch (error) {
                console.error("Error fetching chat clients:", error);
                setError("Không thể tải danh sách khách hàng. Vui lòng thử lại sau.");
                setLoading(false);
            }
        };

        fetchChatClients();

        // Thiết lập interval để cập nhật danh sách mỗi 30 giây
        const intervalId = setInterval(fetchChatClients, 30000);

        // Cleanup khi component unmount
        return () => clearInterval(intervalId);
    }, []);

    const formatTime = (timestamp) => {
        if (!timestamp) return 'N/A';
        try {
            return format(new Date(timestamp), 'dd/MM/yyyy HH:mm');
        } catch (error) {
            return 'Invalid date';
        }
    };

    const handleClientClick = (clientId) => {
        navigate(`/admin/chat/${clientId}`);
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ p: 3 }}>
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ p: 2 }}>
            <Typography variant="h5" sx={{ mb: 2 }}>Danh sách khách hàng chat</Typography>
            {chatClients.length === 0 ? (
                <Paper sx={{ p: 3, textAlign: 'center' }}>
                    <Typography>Không có khách hàng nào</Typography>
                </Paper>
            ) : (
                <Paper>
                    <List sx={{ maxHeight: 600, overflow: 'auto' }}>
                        {chatClients.map((client, index) => (
                            <React.Fragment key={client.clientId}>
                                <ListItem
                                    button
                                    alignItems="flex-start"
                                    onClick={() => handleClientClick(client.clientId)}
                                    sx={{
                                        '&:hover': {
                                            bgcolor: 'rgba(0, 0, 0, 0.04)'
                                        }
                                    }}
                                >
                                    <ListItemAvatar>
                                        <Avatar sx={{ bgcolor: client.isOnline ? '#4caf50' : '#9e9e9e' }}>
                                            <PersonIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Typography component="span" variant="subtitle1">
                                                    Khách hàng: {client.clientId}
                                                </Typography>
                                                {client.isOnline && (
                                                    <Box
                                                        sx={{
                                                            width: 8,
                                                            height: 8,
                                                            borderRadius: '50%',
                                                            bgcolor: '#4caf50',
                                                            ml: 1
                                                        }}
                                                    />
                                                )}
                                            </Box>
                                        }
                                        secondary={
                                            <>
                                                <Typography component="span" variant="body2" color="text.primary">
                                                    {client.lastMessage ? client.lastMessage.substring(0, 50) + (client.lastMessage.length > 50 ? '...' : '') : 'Chưa có tin nhắn'}
                                                </Typography>
                                                <br />
                                                {client.lastTimestamp && `Lúc: ${formatTime(client.lastTimestamp)}`}
                                            </>
                                        }
                                    />
                                </ListItem>
                                {index < chatClients.length - 1 && <Divider variant="inset" component="li" />}
                            </React.Fragment>
                        ))}
                    </List>
                </Paper>
            )}
        </Box>
    );
};

export default ChatDashboard;