// Commnent this like search blog
// Add blog constant
export const ADD_BLOG_REQUEST = "ADD_BLOG_REQUEST"
export const ADD_BLOG_SUCCESS = "ADD_BLOG_SUCCESS"
export const ADD_BLOG_FAILED = "ADD_BLOG_FAILED"

// Get blog constant
export const GET_NEW_BLOGS_LIST_REQUEST = "GET_NEW_BLOGS_LIST_REQUEST"
export const GET_NEW_BLOGS_LIST_SUCCESS = "GET_NEW_BLOGS_LIST_SUCCESS"
export const GET_NEW_BLOGS_LIST_FAILED = "GET_NEW_BLOGS_LIST_FAILED"
export const GET_DETAIL_BLOG_REQUEST = "GET_DETAIL_BLOG_REQUEST"
export const GET_DETAIL_BLOG_SUCCESS = "GET_DETAIL_BLOG_SUCCESS"
export const GET_DETAIL_BLOG_FAILED = "GET_DETAIL_BLOG_FAILED"
export const GET_4_RECENT_BLOGS_REQUEST = "GET_4_RECENT_BLOGS_REQUEST"
export const GET_4_RECENT_BLOGS_SUCCESS = "GET_4_RECENT_BLOGS_SUCCESS"
export const GET_4_RECENT_BLOGS_FAILED = "GET_4_RECENT_BLOGS_FAILED"
export const GET_BLOGS_BY_TAGS_REQUEST = "GET_BLOGS_BY_TAGS_REQUEST"
export const GET_BLOGS_BY_TAGS_SUCCESS = "GET_BLOGS_BY_TAGS_SUCCESS"
export const GET_BLOGS_BY_TAGS_FAILED = "GET_BLOGS_BY_TAGS_FAILED"

// Delete Blog Action
export const DELETE_BLOG_REQUEST = "DELETE_BLOG_REQUEST"
export const DELETE_BLOG_SUCCESS = "DELETE_BLOG_SUCCESS"
export const DELETE_BLOG_FAILED = "DELETE_BLOG_FAILED"

// Update Blog Action
export const UPDATE_BLOG_REQUEST = "UPDATE_BLOG_REQUEST"
export const UPDATE_BLOG_SUCCESS = "UPDATE_BLOG_SUCCESS"
export const UPDATE_BLOG_FAILED = "UPDATEE_BLOG_FAILED"

// Search Blog Action
export const SEARCH_BLOG_REQUEST = "SEARCH_BLOG_REQUEST"
export const SEARCH_BLOG_SUCCESS = "SEARCH_BLOG_SUCCESS"
export const SEARCH_BLOG_FAILED = "SEARCH_BLOG_FAILED"
