import Client from "app/modules/ClientModules";
import Admin from "app/modules/AdminModules";
import CourseOnline from "app/modules/CourseOnlineModules";
import { MEDIA_PATH_CLIENT } from "./routes-coursemedia";
import { ELEARNING_PATH_CLIENT } from "./routes-elearning";
import { OFFLINE_PATH_CLIENT } from "./routes-coursesoffline";
import { ONLINE_PATH_CLIENT } from "./routes-coursesonline";


// PATH FOR MODULES CLIENT
export const URL_PATH_CLIENT = {
  HOME: "/",
  HOMECLIENTV2: "/v2",
  LOGIN: "/dang-nhap",
  REGISTER: "/dang-ky",
  ACCOUNT_DETAIL: "/thong-tin-tai-khoan/:screen",
  MRVAS: "/sr/mr-vas",
  MSLAHAGIANGTHANH: "/sr/ms-la-ha-giang-thanh",
  VISIONANDMISSION: "/sr/tam-nhin-su-menh-sr",
  RULES: "/sr/noi-quy-quy-dinh",
  COURSE: "/chuong-trinh-huan-luyen",
  COURSEMEDIA: "/chuong-trinh-huan-luyen/media",
  RECRUITMENT: "/sr/tuyen-dung",
  CONFIRMREGISTER: "/xac-nhan-dang-ky/:token",
  CONFIRMFORGOTPASSWORD: "/xac-nhan-quen-mat-khau/:token",
  FORGOTPASSWORD: "/quen-mat-khau",
  BLOG_LIST: "/tram-doc-cam",
  BLOG_DETAIL: "/tram-doc-cam/:url",
  LIFE_MAP: "/life-map",
  PERFORMANCE: "/hop-tac/toi-uu-thanh-tich",
  CORPORATION: "/hop-tac/huan-luyen-doanh-nghiep",
  EDUCATION: "/hop-tac/dao-tao-the-he-tre",
  COACH: "/hop-tac/tu-van-tri-lieu-ca-nhan",
  DEMO: "/demo",
  // The Restart URL
  THERESTART: "/the-restart",
  THERESTARTCORAU: "/the-restart/follower-co-rau",
  THERESTARTVIETMY: "/the-restart/viet-my",
  // Game The Restart
  GAMETHERESTART: "/game-the-restart",
  // Game SM
  GAME_SM: "/game-sm",
  KEYTOSUCCESS: "/chia-khoa-thanh-cong",
  VNPAY_RETURN: "order/vnpay-return",
  IPN: "order/ipn",
  ORDER_DETAIL: "order/chi-tiet-don-hang",
  QUESTION_PSYCHOLOGICAL: "cau-hoi-tam-ly",
  PAYMENT_POLICY: "chinh-sach-thanh-toan-online",
  PAYMENT_GUIDE: "huong-dan-thanh-toan-online",
  MOUNTAIN_OVERVIEW: "/khoa-hoc-online",
  CONQUER_FANSIPAN: "/chinh-phuc-fansipan",
  BLOG_HOMEPAGE: "/tin-tuc",
  BLOG_SEARCH: "/tin-tuc/tim-kiem",
  BLOG_ALL: "/tin-tuc/tat-ca",
  KICKSTART_EVENT: "/kick-start",
  MembershipBenefits: "/MembershipBenefits",
  EventDetails: "/EventDetails",
  BLOG_DETAIL: "/tin-tuc/:id",
  BLOG_TAGS_PAGE: "/tin-tuc/tag/:id",
  MEMBERSHIP_OVERVIEW: "/tong-quan-membership",
  PSY_TEST: "/psy-test",
  PSY_TEST_DETAIL: "/psy-test/:id",
};

// PATH FOR MODULES ADMIN
export const URL_PATH_ADMIN = {
  HOME: "/",
  USER_MANAGER: "/quan-ly-nguoi-dung",
  PRODUCT_MANAGER: "/quan-ly-san-pham",
  ADD_USER: "/them-nguoi-dung",
  BLOG_EDIT: "/sua-bai/:id",
  ADD_POST: "/them-bai-viet",
  FIX_POST: "/chinh-sua-bai-viet",
  FIX_POST_DETAIL: "/chinh-sua-bai-viet-chi-tiet/:id",
  BLOG_MANAGER: "/quan-ly-bai-viet",
  STUDENT_MANAGER: "/quan-ly-hoc-vien",
  COURSE_MANAGER: "/quan-ly-khoa-hoc",
  COURSE_ONLINE_MANAGER: "/quan-ly-khoa-hoc-truc-tuyen",
  COURSE_ONLINE_MANAGER_DETAIL:
    "/quan-ly-khoa-hoc-truc-tuyen/chi-tiet-khoa-hoc",
  SCHEDULE_COURSE_ONLINE_MANAGER: "/quan-ly-lich-hoc",
  EDIT_SCHEDULE_COURSE_ONLINE_MANAGER: "/quan-ly-lich-hoc/chinh-sua-lich-hoc",
  QUESTION_MANAGER: "/quan-ly-cau-hoi",
  VIDEO_MANAGER: "/quan-ly-video",
  PAYMENT: "/thanh-toan",
  CHAT_DASHBOARD: "/bang-dieu-khien-tin-nhan",
  CHAT_WITH_CLIENT: "/chat/:clientId",
};

// Public Pages for HOME Template
export const publicRoutes = [
  {
    component: Client.Home,
    exact: true,
    path: URL_PATH_CLIENT.HOME,
  },

  {
    component: Client.OrderDetails,
    exact: true,
    path: URL_PATH_CLIENT.ORDER_DETAIL,
  },
  {
    component: Client.VNPayReturn,
    exact: true,
    path: URL_PATH_CLIENT.VNPAY_RETURN,
  },
  {
    component: Client.IPN,
    exact: true,
    path: URL_PATH_CLIENT.IPN,
  },

  {
    component: Client.QuestionForUser,
    exact: true,
    path: URL_PATH_CLIENT.QUESTION_PSYCHOLOGICAL,
  },

  {
    component: Client.KickStart,
    exact: true,
    path: URL_PATH_CLIENT.KICKSTART_EVENT,
  },

  {
    component: Client.PaymentPolicy,
    exact: true,
    path: URL_PATH_CLIENT.PAYMENT_POLICY,
  },
  {
    component: Client.PaymentGuide,
    exact: true,
    path: URL_PATH_CLIENT.PAYMENT_GUIDE,
  },
  {
    component: Client.MembershipBenefits,
    exact: true,
    path: URL_PATH_CLIENT.MembershipBenefits,
  },
  {
    component: Client.EventDetails,
    exact: true,
    path: URL_PATH_CLIENT.EventDetails,
  },
  // The Origin Online
  // {
  //   component: Client.CourseOnlineSelfMastery,
  //   exact: true,
  //   path: URL_PATH_CLIENT.HOME,
  // },
  {
    component: Client.MountainOverview,
    exact: true,
    path: URL_PATH_CLIENT.MOUNTAIN_OVERVIEW,
  },
  {
    component: Client.ConquerFansipan,
    exact: true,
    path: URL_PATH_CLIENT.CONQUER_FANSIPAN,
  },
  {
    component: Client.BlogHomePage,
    exact: true,
    path: URL_PATH_CLIENT.BLOG_HOMEPAGE,
  },
  {
    component: Client.BlogAll,
    exact: true,
    path: URL_PATH_CLIENT.BLOG_ALL,
  },
  // Search Blog
  {
    component: Client.SearchResultsPage,
    exact: true,
    path: URL_PATH_CLIENT.BLOG_SEARCH,
  },
  {
    component: Client.BlogDetailPage,
    exact: true,
    path: URL_PATH_CLIENT.BLOG_DETAIL,
  },
  {
    component: Client.BlogTagsPage,
    exact: true,
    path: URL_PATH_CLIENT.BLOG_TAGS_PAGE,
  },
  {
    component: Client.MembershipOverview,
    exact: true,
    path: URL_PATH_CLIENT.MEMBERSHIP_OVERVIEW,
  },
  {
    component: Client.Fansipan,
    exact: true,
    path: ELEARNING_PATH_CLIENT.FANSIPAN,
  },

  {
    component: Client.Kinabalu,
    exact: true,
    path: ELEARNING_PATH_CLIENT.KINABALU,
  },
  {
    component: Client.Kilimanjaro,
    exact: true,
    path: ELEARNING_PATH_CLIENT.KILIMANJARO,
  },
  {
    component: Client.Aconcagua,
    exact: true,
    path: ELEARNING_PATH_CLIENT.ACONCAGUA,
  },
  {
    component: Client.Demo,
    exact: true,
    path: URL_PATH_CLIENT.DEMO,
  },
  {
    component: Client.AccountDetail,
    exact: true,
    path: URL_PATH_CLIENT.ACCOUNT_DETAIL,
  },

  {
    component: Client.HomeClientV2,
    exact: true,
    path: URL_PATH_CLIENT.HOMECLIENTV2,
  },
  {
    component: Client.Login,
    exact: true,
    path: URL_PATH_CLIENT.LOGIN,
  },
  {
    component: Client.MrVas,
    exact: true,
    path: URL_PATH_CLIENT.MRVAS,
  },
  {
    component: Client.MsLaHaGiangThanh,
    exact: true,
    path: URL_PATH_CLIENT.MSLAHAGIANGTHANH,
  },
  {
    component: Client.VisionPage,
    exact: true,
    path: URL_PATH_CLIENT.VISIONANDMISSION,
  },
  {
    component: Client.Rules,
    exact: true,
    path: URL_PATH_CLIENT.RULES,
  },
  // The Restart URL
  {
    component: Client.CourseOfflineTheRestart,
    exact: true,
    path: URL_PATH_CLIENT.THERESTART,
  },
  {
    component: Client.CourseOfflineTheRestart,
    exact: true,
    path: URL_PATH_CLIENT.THERESTARTCORAU,
  },
  {
    component: Client.CourseOfflineTheRestart,
    exact: true,
    path: URL_PATH_CLIENT.THERESTARTVIETMY,
  },
  // Game The Restart
  {
    component: Client.GameTheRestart,
    exact: true,
    path: URL_PATH_CLIENT.GAMETHERESTART,
  },
  {
    component: Client.GameSM,
    exact: true,
    path: URL_PATH_CLIENT.GAME_SM,
  },
  {
    component: Client.CourseOfflineKeyToSuccess,
    exact: true,
    path: URL_PATH_CLIENT.KEYTOSUCCESS,
  },
  {
    component: Client.Course,
    exact: true,
    path: URL_PATH_CLIENT.COURSE,
  },
  {
    component: Client.CourseOffline,
    exact: true,
    path: OFFLINE_PATH_CLIENT.COURSEOFFLINE,
  },
  {
    component: Client.CourseOfflinePP,
    exact: true,
    path: OFFLINE_PATH_CLIENT.khoa1,
  },

  {
    component: Client.CourseOfffLineTheFirstStep,
    exact: true,
    path: OFFLINE_PATH_CLIENT.theFirstStep,
  },
  {
    component: Client.CourseOfflineSM,
    exact: true,
    path: OFFLINE_PATH_CLIENT.SM,
  },
  {
    component: Client.CourseOfflineUM,
    exact: true,
    path: OFFLINE_PATH_CLIENT.UM,
  },
  {
    component: Client.CourseOfflinePR,
    exact: true,
    path: OFFLINE_PATH_CLIENT.khoa2,
  },
  {
    component: Client.CourseOfflineIYL,
    exact: true,
    path: OFFLINE_PATH_CLIENT.khoa3,
  },
  {
    component: Client.CourseOfflinePH,
    exact: true,
    path: OFFLINE_PATH_CLIENT.khoa4,
  },
  {
    component: Client.CourseOfflineMOE,
    exact: true,
    path: OFFLINE_PATH_CLIENT.khoa5,
  },
  {
    component: Client.CourseOfflineICPC,
    exact: true,
    path: OFFLINE_PATH_CLIENT.ICPC,
  },

  {
    component: Client.CourseOfflineGRADUATE,
    exact: true,
    path: OFFLINE_PATH_CLIENT.gol,
  },
  {
    component: Client.CourseOfflineOAC,
    exact: true,
    path: OFFLINE_PATH_CLIENT.OAC,
  },
  {
    component: Client.CourseOfflineLTC,
    exact: true,
    path: OFFLINE_PATH_CLIENT.LTC,
  },
  {
    component: Client.CourseOfflineIMCPC,
    exact: true,
    path: OFFLINE_PATH_CLIENT.IMCPC,
  },
  {
    component: Client.CourseOfflineITTC,
    exact: true,
    path: OFFLINE_PATH_CLIENT.ITTC,
  },

  {
    component: Client.CourseMedia,
    exact: true,
    path: URL_PATH_CLIENT.COURSEMEDIA,
  },
  {
    component: Client.ComboCourseMedia1,
    exact: true,
    path: MEDIA_PATH_CLIENT.COMBO1,
  },
  {
    component: Client.Tower1,
    exact: true,
    path: MEDIA_PATH_CLIENT.TOWER1,
  },
  {
    component: Client.VideoStreaming,
    exact: true,
    path: MEDIA_PATH_CLIENT.VIDEOSTREAMING,
  },
  {
    component: Client.Register,
    exact: true,
    path: URL_PATH_CLIENT.REGISTER,
  },

  {
    component: Client.Recruitment,
    exact: true,
    path: URL_PATH_CLIENT.RECRUITMENT,
  },
  {
    component: Client.ConfirmRegister,
    exact: true,
    path: URL_PATH_CLIENT.CONFIRMREGISTER,
  },
  {
    component: Client.VerifyForgotPassword,
    exact: true,
    path: URL_PATH_CLIENT.CONFIRMFORGOTPASSWORD,
  },
  {
    component: Client.ForgotPassword,
    exact: true,
    path: URL_PATH_CLIENT.FORGOTPASSWORD,
  },
  {
    component: Client.BlogList,
    exact: true,
    path: URL_PATH_CLIENT.BLOG_LIST,
  },
  {
    component: Client.BlogDetail,
    exact: true,
    path: URL_PATH_CLIENT.BLOG_DETAIL,
  },
  {
    component: Client.LifeMap,
    exact: true,
    path: URL_PATH_CLIENT.LIFE_MAP,
  },
  {
    component: Client.Coach,
    exact: true,
    path: URL_PATH_CLIENT.COACH,
  },
  {
    component: Client.Performance,
    exact: true,
    path: URL_PATH_CLIENT.PERFORMANCE,
  },
  {
    component: Client.Education,
    exact: true,
    path: URL_PATH_CLIENT.EDUCATION,
  },
  {
    component: Client.Corporation,
    exact: true,
    path: URL_PATH_CLIENT.CORPORATION,
  },
  {
    component: Client.PsyTest,
    exact: true,
    path: URL_PATH_CLIENT.PSY_TEST,
  },
  {
    component: Client.PsyTestDetail,
    exact: true,
    path: URL_PATH_CLIENT.PSY_TEST_DETAIL,
  }
];
// public pages for Course Online Template

export const courseOnlineRoutes = [
  {
    component: Client.CourseOnlineSelfMastery,
    exact: true,
    path: ONLINE_PATH_CLIENT.SELFMASTERY,
  },
  {
    component: Client.CourseOnlineTower,
    exact: true,
    path: ONLINE_PATH_CLIENT.TOWER,
  },

  // {
  //   component: CourseOnline.CourseOnlineHome,
  //   exact: true,
  //   path: ONLINE_PATH_CLIENT.COURSEONLINEHOME,
  //   title: "home_page",
  // },
  {
    component: CourseOnline.CourseOnlineDetails.TowerOne,
    exact: true,
    path: ONLINE_PATH_CLIENT.TOWERONE,
    title: "Tháp 1",
  },
];

// Only for Admin Pages
export const adminRoutes = [
  {
    component: Admin.Home,
    exact: true,
    path: "/admin" + URL_PATH_ADMIN.HOME,
  },
  {
    component: Admin.UserManager,
    exact: true,
    path: "/admin" + URL_PATH_ADMIN.USER_MANAGER,
  },
  {
    component: Admin.ProductsManager,
    exact: true,
    path: "/admin" + URL_PATH_ADMIN.PRODUCT_MANAGER,
  },
  {
    component: Admin.AddUser,
    exact: true,
    path: "/admin" + URL_PATH_ADMIN.ADD_USER,
  },
  {
    component: Admin.AddPost,
    exact: true,
    path: "/admin" + URL_PATH_ADMIN.ADD_POST,
  },
  {
    component: Admin.FixPost,
    exact: true,
    path: "/admin" + URL_PATH_ADMIN.FIX_POST,
  },
  {
    component: Admin.FixPostDetail,
    exact: true,
    path: "/admin" + URL_PATH_ADMIN.FIX_POST_DETAIL,
  },
  {
    component: Admin.BlogsManager,
    exact: true,
    path: "/admin" + URL_PATH_ADMIN.BLOG_MANAGER,
  },
  {
    component: Admin.EditPost,
    exact: true,
    path: "/admin" + URL_PATH_ADMIN.BLOG_EDIT,
  },
  {
    component: Admin.Student,
    exact: true,
    path: "/admin" + URL_PATH_ADMIN.STUDENT_MANAGER,
  },
  {
    component: Admin.Course,
    exact: true,
    path: "/admin" + URL_PATH_ADMIN.COURSE_MANAGER,
  },

  {
    component: Admin.CourseOnlineManager,
    exact: true,
    path: "/admin" + URL_PATH_ADMIN.COURSE_ONLINE_MANAGER,
  },
  {
    component: Admin.CreateCourseOnline,
    exact: true,
    path: "/admin" + URL_PATH_ADMIN.COURSE_ONLINE_MANAGER_DETAIL,
  },
  {
    component: Admin.ScheduleCourseOnlineManager,
    exact: true,
    path: "/admin" + URL_PATH_ADMIN.SCHEDULE_COURSE_ONLINE_MANAGER,
  },
  {
    component: Admin.EditScheduleCourseOnlineManager,
    exact: true,
    path: "/admin" + URL_PATH_ADMIN.EDIT_SCHEDULE_COURSE_ONLINE_MANAGER,
  },

  {
    component: Admin.VideoManager,
    exact: true,
    path: "/admin" + URL_PATH_ADMIN.VIDEO_MANAGER,
  },
  {
    component: Admin.PaymentManager,
    exact: true,
    path: "/admin" + URL_PATH_ADMIN.PAYMENT,
  },

  {
    component: Admin.QuestionManager,
    exact: true,
    path: "/admin" + URL_PATH_ADMIN.QUESTION_MANAGER,
  },
  {
    component: Admin.ChatDashboard,
    exact: true,
    path: "/admin" + URL_PATH_ADMIN.CHAT_DASHBOARD,
  },
  {
    component: Admin.ChatPage,
    exact: true,
    path: "/admin" + URL_PATH_ADMIN.CHAT_WITH_CLIENT,
  },
];
