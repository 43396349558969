import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSpecificBlog, updateBlog } from "core/redux/actions/newBlogAction";
import { useParams, useNavigate } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import { tagsData } from "app/modules/ClientModules/const";
import ReactQuill from "react-quill";
import {
    Container,
    TextField,
    FormControlLabel,
    Checkbox,
    Button,
    Typography,
    Paper,
    CircularProgress,
    Box,
    Switch,
    Alert,
} from "@mui/material";

function FixPostDetail() {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { specificBlog, loading, error } = useSelector((state) => state.newblog);
    const quillRef = useRef(null);

    const imageHandler = useCallback(() => {
        if (quillRef.current) {
            const quill = quillRef.current.getEditor();
            const range = quill.getSelection() || { index: 0 };
            const imageUrl = prompt("Nhập URL ảnh:");
            if (imageUrl) {
                quill.insertEmbed(range.index, "image", imageUrl);
            }
        }
    }, []);

    const modules = {
        toolbar: {
            container: [
                [{ header: [1, 2, 3, false] }],
                [{ font: [] }, { size: [] }],
                ["bold", "italic", "underline"],
                [{ color: [] }, { background: [] }],
                [{ list: "ordered" }, { list: "bullet" }],
                ["link", "image"],
                [{ align: [] }],
            ],
            handlers: { image: imageHandler },
        },
    };

    const [blogData, setBlogData] = useState({
        title: "",
        imgBackground: "",
        content: "",
        tags: [],
        standOut: false,
    });

    useEffect(() => {
        dispatch(getSpecificBlog(id));
    }, [id, dispatch]);

    useEffect(() => {
        if (specificBlog && Object.keys(specificBlog).length > 0) {
            setBlogData({
                title: specificBlog.title || "",
                imgBackground: specificBlog.imgBackground || "",
                content: specificBlog.content || "",
                tags: specificBlog.tags || [],
                standOut: specificBlog.standOut || false,
            });
        }
    }, [specificBlog]);

    const handleChange = (field, value) => {
        setBlogData((prev) => ({ ...prev, [field]: value }));
    };

    const handleTagChange = (tag) => {
        setBlogData((prev) => ({
            ...prev,
            tags: prev.tags.includes(tag) ? prev.tags.filter((t) => t !== tag) : [...prev.tags, tag],
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(updateBlog(id, blogData))
            .then(() => {
                alert("Cập nhật thành công!");
                navigate("/tin-tuc");
            })
            .catch((err) => console.error("Lỗi cập nhật:", err));
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Container maxWidth="md">
            <Paper elevation={3} sx={{ padding: 3, marginTop: 4 }}>
                <Typography variant="h4" gutterBottom>
                    Chỉnh Sửa Blog
                </Typography>

                {error && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        {error}
                    </Alert>
                )}

                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Tiêu đề"
                        fullWidth
                        margin="normal"
                        value={blogData.title}
                        onChange={(e) => handleChange("title", e.target.value)}
                    />

                    <TextField
                        label="Hình nền"
                        fullWidth
                        margin="normal"
                        value={blogData.imgBackground}
                        onChange={(e) => handleChange("imgBackground", e.target.value)}
                    />

                    <Typography variant="h6" gutterBottom sx={{ marginTop: 2 }}>
                        Tags
                    </Typography>
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
                        {tagsData.map((tag, index) => (
                            <FormControlLabel
                                key={index}
                                control={
                                    <Checkbox
                                        checked={blogData.tags.includes(index)}
                                        onChange={() => handleTagChange(index)}
                                    />
                                }
                                label={tag}
                            />
                        ))}
                    </Box>

                    <FormControlLabel
                        control={
                            <Switch
                                checked={blogData.standOut}
                                onChange={(e) => handleChange("standOut", e.target.checked)}
                            />
                        }
                        label="Đánh dấu nổi bật"
                    />

                    <Typography variant="h6" gutterBottom>
                        Nội dung
                    </Typography>
                    <Box
                        sx={{
                            "& .ql-toolbar": {
                                position: "sticky",
                                top: 0,
                                zIndex: 1000,
                                backgroundColor: "#fff",
                                borderBottom: "1px solid #ccc",
                            },
                            "& .ql-container": {
                                height: "400px",
                                overflowY: "auto",
                            },
                        }}
                    >
                        <ReactQuill
                            ref={quillRef}
                            value={blogData.content}
                            modules={modules}
                            onChange={(content) => handleChange("content", content)}
                        />
                    </Box>

                    <Box mt={3} sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Button variant="outlined" color="secondary" onClick={() => navigate("/tin-tuc")}>
                            Quay lại
                        </Button>
                        <Button type="submit" variant="contained" color="primary">
                            Lưu Thay Đổi
                        </Button>
                    </Box>
                </form>
            </Paper>
        </Container>
    );
}

export default FixPostDetail;
