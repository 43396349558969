import { lazy } from "react";

const Home = lazy(() => import("./home"));
const HomeClientV2 = lazy(() => import("./homeClientV2"));
// Auth
const Login = lazy(() => import("./login"));
const AccountDetail = lazy(() => import("./accountDetail"));
const ConfirmRegister = lazy(() => import("./confirmRegister"));
const ForgotPassword = lazy(() => import("./forgotPassword"));
const VerifyForgotPassword = lazy(() => import("./confirmForgotPassword"));
// Course
const Course = lazy(() => import("./courses/summary"));
const CourseOnline = lazy(() => import("./courses/courseOnline"));
const CourseOffline = lazy(() => import("./courses/courseOffline"));
const CourseMedia = lazy(() => import("./courses/courseMedia"));
// course offline combo 10
const CourseOfflineTheRestart = lazy(() => import("./courses/courseOffline/tr"));
const CourseOfffLineTheFirstStep = lazy(() => import("./courses/courseOffline/1st"));
const CourseOfflineKeyToSuccess = lazy(() => import("./courses/courseOffline/keytosuccess"));
const CourseOfflineSM = lazy(() => import("./courses/courseOffline/sm"));
const CourseOfflineUM = lazy(() => import("./courses/courseOffline/um"));
const CourseOfflineIYL = lazy(() => import("./courses/courseOffline/iyl"));
const CourseOfflineMOE = lazy(() => import("./courses/courseOffline/moe"));
const CourseOfflineICPC = lazy(() => import("./courses/courseOffline/icpc"));
const CourseOfflineBAV = lazy(() => import("./courses/courseOffline/bav"));
const CourseOfflineLEADERSHIP = lazy(() => import("./courses/courseOffline/leadership"));
const CourseOfflineIDENTITY = lazy(() => import("./courses/courseOffline/identity"));
const CourseOfflineGRADUATE = lazy(() => import("./courses/courseOffline/graduate"));

// 3 khóa khác
const CourseOfflineOAC = lazy(() => import("./courses/courseOffline/oac"));
const CourseOfflineLTC = lazy(() => import("./courses/courseOffline/ltc"));
const CourseOfflinePAR = lazy(() => import("./courses/courseOffline/par"));

// ICTA
const CourseOfflineIMCPC = lazy(() => import("./courses/courseOffline/imcpc"));
const CourseOfflineITTC = lazy(() => import("./courses/courseOffline/ittc"));
// course online
const CourseOnlineTower = lazy(() => import("./courses/courseOnline/tower"));
const CourseOnlineSelfMastery = lazy(() => import("./courses/courseOnline/selfMastery"));
const CourseOnlineRUNFASTER = lazy(() => import("./courses/courseOnline/runFaster"));
const CourseOnlineRFREGISTER = lazy(() => import("./courses/courseOnline/runFaster/register"));
const CourseOnlineCCS = lazy(() => import("./courses/courseOnline/ccs"));
const CourseOnlineBFW = lazy(() => import("./courses/courseOnline/bfw"));
const CourseOnlineVL = lazy(() => import("./courses/courseOnline/visionaryLeadership"));
// LPE
const MrVas = lazy(() => import("./lpe/mrVas"));
const MsLaHaGiangThanh = lazy(() => import("./lpe/msLaHaGiangThanh"));
const VisionPage = lazy(() => import("./lpe/visionAndMissionLPE"));
const Rules = lazy(() => import("./lpe/rules"));
const Recruitment = lazy(() => import("./lpe/recruitment"));

// course Media
const ComboCourseMedia1 = lazy(() => import("./courses/courseMedia/combo/comboCourseMedia1"));
const Tower1 = lazy(() => import("./courses/courseMedia/tower1"));
const VideoStreaming = lazy(() => import("./courses/courseMedia/videoStreaming"));
// Blog
const BlogList = lazy(() => import("./blogList"));
const BlogDetail = lazy(() => import("./blogDetail"));

// Map
const LifeMap = lazy(() => import("./lifeMap"));

const Register = lazy(() => import("./register"));

// Demo
const Demo = lazy(() => import("./demo"));

// Corperate
const Education = lazy(() => import("./cooperate").then(module => ({ default: module.Education })));
const Performance = lazy(() => import("./cooperate").then(module => ({ default: module.Performance })));
const Corporation = lazy(() => import("./cooperate").then(module => ({ default: module.Corporation })));
const Coach = lazy(() => import("./cooperate").then(module => ({ default: module.Coach })));

const VNPayReturn = lazy(() => import("./order/vnpayReturn"));
const IPN = lazy(() => import("./order/ipn"));
const OrderDetails = lazy(() => import("./order/orderDetails"));
const QuestionForUser = lazy(() => import("./questionForUser"));
const PaymentPolicy = lazy(() => import("./paymentPolicy"));
const PaymentGuide = lazy(() => import("./paymentGuide"));
const MountainOverview = lazy(() => import("../CourseOnlineModules/courseOnlineDetails/mountainOverview"));
const Fansipan = lazy(() => import("../CourseOnlineModules/courseOnlineDetails/fansipan"));
const Kinabalu = lazy(() => import("../CourseOnlineModules/courseOnlineDetails/kinabalu"));
const Kilimanjaro = lazy(() => import("../CourseOnlineModules/courseOnlineDetails/kilimanjaro"));
const ConquerFansipan = lazy(() => import("../CourseOnlineModules/courseOnlineDetails/mountainClimbingPromote"));
const Aconcagua = lazy(() => import("../CourseOnlineModules/courseOnlineDetails/aconcagua"));
const BlogHomePage = lazy(() => import("./blogHome"));
const BlogAll = lazy(() => import("./blogAll"));
const KickStart = lazy(() => import("./kickStart"));
const MembershipBenefits = lazy(() => import("./MembershipBenefits"));
const EventDetails = lazy(() => import("./eventdetail"));
const BlogDetailPage = lazy(() => import("./blogDetails"));
const BlogTagsPage = lazy(() => import("./blogTags"));
const MembershipOverview = lazy(() => import("./membership"));
const PsyTest = lazy(() => import("./psyTest"));
const PsyTestDetail = lazy(() => import("./psyTest/testDetail"));
const SearchResultsPage = lazy(() => import("./searchResultsPage"));
const GameTheRestart = lazy(() => import("./gameTheRestart"));
const GameSM = lazy(() => import("./gameSM"));
const Client = {
  Home,
  HomeClientV2,
  Login,
  AccountDetail,
  MrVas,
  MsLaHaGiangThanh,
  VisionPage,
  Rules,
  Course,
  CourseOnline,
  CourseOffline,
  CourseOfffLineTheFirstStep,
  CourseOfflineTheRestart,
  CourseOfflineKeyToSuccess,
  CourseOfflineSM,
  CourseOfflineUM,
  CourseOfflineIYL,

  CourseOfflineMOE,
  CourseOfflineICPC,
  CourseOfflineBAV,
  CourseOfflineLEADERSHIP,
  CourseOfflineIDENTITY,
  CourseOfflineGRADUATE,
  CourseOfflineOAC,
  CourseOfflineLTC,
  CourseOfflinePAR,
  CourseOfflineIMCPC,
  CourseOfflineITTC,
  CourseOnlineSelfMastery,
  CourseOnlineRUNFASTER,
  CourseOnlineRFREGISTER,
  CourseOnlineBFW,
  CourseOnlineCCS,
  CourseOnlineVL,
  CourseOnlineTower,
  CourseMedia,
  ComboCourseMedia1,
  Tower1,
  VideoStreaming,
  Register,
  Recruitment,
  ConfirmRegister,
  ForgotPassword,
  VerifyForgotPassword,
  BlogList,
  BlogDetail,
  LifeMap,
  Demo,
  Performance,
  Education,
  Corporation,
  Coach,
  VNPayReturn,
  IPN,
  OrderDetails,
  QuestionForUser,
  PaymentPolicy,
  PaymentGuide,
  MountainOverview,
  ConquerFansipan,
  Fansipan,
  Kinabalu,
  Kilimanjaro,
  Aconcagua,
  BlogHomePage,
  BlogDetailPage,
  BlogAll,
  KickStart,
  BlogTagsPage,
  MembershipBenefits,
  EventDetails,
  MembershipOverview,
  PsyTest, // Update 21/2/2025
  PsyTestDetail, // Update 21/2/2025
  SearchResultsPage, // Update 3/6/2025 - Harry
  GameTheRestart, // Update 20/3/2025 - Harry
  GameSM, // Update 29/3/2025 - Harry
};

export default Client;
