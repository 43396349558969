import {
    GET_COMMENTS_REQUEST,
    GET_COMMENTS_SUCCESS,
    GET_COMMENTS_FAILED,
    ADD_COMMENT_REQUEST,
    ADD_COMMENT_SUCCESS,
    ADD_COMMENT_FAILED,
    DELETE_COMMENT_REQUEST,
    DELETE_COMMENT_SUCCESS,
    DELETE_COMMENT_FAILED,
    UPDATE_COMMENT_REQUEST,
    UPDATE_COMMENT_SUCCESS,
    UPDATE_COMMENT_FAILED
} from "../../constant/commentConstant"

const initialState = {
    loading: false,
    comments: [],
    error: null
}

export const commentReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_COMMENTS_REQUEST:
            return { ...state, loading: true }
        case GET_COMMENTS_SUCCESS:
            return { ...state, loading: false, comments: action.payload, error: null }
        case GET_COMMENTS_FAILED:
            return { ...state, loading: false, error: action.payload }

        case ADD_COMMENT_REQUEST:
            return { ...state, loading: true }
        case ADD_COMMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                comments: [...state.comments, action.payload],
                error: null
            }
        case ADD_COMMENT_FAILED:
            return { ...state, loading: false, error: action.payload }

        case DELETE_COMMENT_REQUEST:
            return { ...state, loading: true }
        case DELETE_COMMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                comments: state.comments.filter(comment => comment._id !== action.payload),
                error: null
            }
        case DELETE_COMMENT_FAILED:
            return { ...state, loading: false, error: action.payload }

        default:
            return state
    }
} 