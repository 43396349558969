import React, { useEffect, useState, useRef } from 'react';
import {
    Box,
    Typography,
    TextField,
    Button,
    Paper,
    Avatar,
    Divider,
    CircularProgress,
    IconButton,
    AppBar,
    Toolbar
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { format } from 'date-fns';
import { database } from '../../../../firebase';
import { ref, onValue, push, set, serverTimestamp } from 'firebase/database';
import { useNavigate } from 'react-router-dom';

const AdminChat = ({ clientId, onBack }) => {
    const [messages, setMessages] = useState([]);
    const [isConnected, setIsConnected] = useState(false);
    const [newMessage, setNewMessage] = useState('');
    const [connecting, setConnecting] = useState(true);
    const [clientStatus, setClientStatus] = useState({ online: false, lastSeen: null });
    const messagesEndRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (!clientId) {
            setConnecting(false);
            setIsConnected(false);
            return;
        }

        // Tham chiếu đến phòng chat của client
        const chatRoomRef = ref(database, `chats/${clientId}`);
        const messagesRef = ref(database, `chats/${clientId}/messages`);
        const typingRef = ref(database, `chats/${clientId}/typing/client`);
        const adminStatusRef = ref(database, `chats/${clientId}/status/admin`);
        const clientStatusRef = ref(database, `chats/${clientId}/status/client`);

        // Thiết lập trạng thái online cho admin
        set(adminStatusRef, {
            online: true,
            lastSeen: serverTimestamp()
        });

        // Lắng nghe tin nhắn mới
        const messagesListener = onValue(messagesRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                const messageList = Object.entries(data).map(([key, value]) => ({
                    id: key,
                    ...value
                }));
                setMessages(messageList);
                setConnecting(false);
                setIsConnected(true);
            } else {
                setMessages([]);
                setConnecting(false);
                setIsConnected(true);
            }
        }, (error) => {
            console.error("Error fetching messages:", error);
            setConnecting(false);
            setIsConnected(false);
        });

        // Lắng nghe trạng thái online của client
        const clientStatusListener = onValue(clientStatusRef, (snapshot) => {
            const status = snapshot.val();
            if (status) {
                setClientStatus(status);
            } else {
                setClientStatus({ online: false, lastSeen: null });
            }
        });

        // Cleanup khi component unmount
        return () => {
            // Ngắt kết nối các listener
            messagesListener();
            clientStatusListener();

            // Cập nhật trạng thái offline cho admin
            set(adminStatusRef, {
                online: false,
                lastSeen: serverTimestamp()
            });
        };
    }, [clientId]);

    // Auto-scroll to bottom when new messages arrive
    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const handleSendMessage = (e) => {
        e.preventDefault();
        if (newMessage.trim() && isConnected) {
            const messageData = {
                sender: 'admin',
                message: newMessage,
                timestamp: new Date().toISOString(),
                isClient: false
            };

            // Thêm tin nhắn vào Firebase
            const messagesRef = ref(database, `chats/${clientId}/messages`);
            push(messagesRef, messageData);

            setNewMessage('');
        }
    };

    const handleTyping = (e) => {
        setNewMessage(e.target.value);

        // Cập nhật trạng thái typing
        const typingRef = ref(database, `chats/${clientId}/typing/admin`);
        set(typingRef, e.target.value.length > 0);
    };

    const formatTime = (timestamp) => {
        try {
            return format(new Date(timestamp), 'HH:mm');
        } catch (error) {
            return '';
        }
    };

    const formatLastSeen = (timestamp) => {
        if (!timestamp) return 'Không xác định';
        try {
            return format(new Date(timestamp), 'dd/MM/yyyy HH:mm:ss');
        } catch (error) {
            return 'Không xác định';
        }
    };

    const handleBack = () => {
        if (onBack) {
            onBack();
        } else {
            navigate('/admin/bang-dieu-khien-tin-nhan');
        }
    };

    if (!clientId) {
        return (
            <Box sx={{ p: 3, textAlign: 'center' }}>
                <Typography variant="h6">Không có khách hàng được chọn</Typography>
                <Button
                    variant="contained"
                    onClick={handleBack}
                    sx={{ mt: 2 }}
                >
                    Quay lại danh sách
                </Button>
            </Box>
        );
    }

    if (connecting) {
        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '400px'
            }}>
                <CircularProgress />
                <Typography variant="body1" sx={{ mt: 2 }}>
                    Đang kết nối đến phòng chat...
                </Typography>
            </Box>
        );
    }

    return (
        <Paper elevation={3} sx={{
            width: '100%',
            height: '100%',
            minHeight: '600px',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            borderRadius: '12px',
            boxShadow: '0 4px 20px rgba(0,0,0,0.1)'
        }}>
            {/* Header */}
            <AppBar position="static" color="primary" sx={{ borderRadius: '12px 12px 0 0' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleBack}
                        sx={{ mr: 2 }}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                        <Avatar sx={{ mr: 1, bgcolor: '#1565c0' }}>
                            {clientId.charAt(0).toUpperCase()}
                        </Avatar>
                        <Box>
                            <Typography variant="h6">Khách hàng: {clientId}</Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box
                                    sx={{
                                        width: 8,
                                        height: 8,
                                        borderRadius: '50%',
                                        bgcolor: clientStatus.online ? '#4caf50' : '#f44336',
                                        mr: 1
                                    }}
                                />
                                <Typography variant="caption">
                                    {clientStatus.online
                                        ? 'Đang trực tuyến'
                                        : `Ngoại tuyến (Truy cập cuối: ${formatLastSeen(clientStatus.lastSeen)})`}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>

            {/* Messages */}
            <Box sx={{
                flexGrow: 1,
                p: 2,
                overflowY: 'auto',
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                bgcolor: '#f5f5f5'
            }}>
                {messages.length === 0 ? (
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                        opacity: 0.7
                    }}>
                        <Typography variant="body1" sx={{ mb: 1 }}>
                            Chưa có tin nhắn nào
                        </Typography>
                        <Typography variant="body2">
                            Hãy bắt đầu cuộc trò chuyện
                        </Typography>
                    </Box>
                ) : (
                    messages.map((msg, index) => (
                        <Box
                            key={msg.id || index}
                            sx={{
                                alignSelf: msg.isClient || msg.sender === clientId ? 'flex-start' : 'flex-end',
                                maxWidth: '80%'
                            }}
                        >
                            <Paper
                                elevation={0}
                                sx={{
                                    p: 1.5,
                                    borderRadius: '18px',
                                    borderBottomRightRadius: msg.isClient || msg.sender === clientId ? '18px' : 0,
                                    borderBottomLeftRadius: msg.isClient || msg.sender === clientId ? 0 : '18px',
                                    bgcolor: msg.isClient || msg.sender === clientId ? 'white' : '#e3f2fd',
                                    wordBreak: 'break-word'
                                }}
                            >
                                <Typography variant="body1">{msg.message}</Typography>
                                <Typography
                                    variant="caption"
                                    sx={{
                                        display: 'block',
                                        textAlign: 'right',
                                        mt: 0.5,
                                        opacity: 0.7
                                    }}
                                >
                                    {formatTime(msg.timestamp)}
                                </Typography>
                            </Paper>
                        </Box>
                    ))
                )}
                <div ref={messagesEndRef} />
            </Box>

            <Divider />

            {/* Input */}
            <Box
                component="form"
                onSubmit={handleSendMessage}
                sx={{
                    p: 2,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    bgcolor: 'white'
                }}
            >
                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Nhập tin nhắn..."
                    value={newMessage}
                    onChange={handleTyping}
                    disabled={!isConnected}
                    size="small"
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '24px',
                        }
                    }}
                />
                <Button
                    type="submit"
                    variant="contained"
                    disabled={!isConnected || !newMessage.trim()}
                    sx={{
                        minWidth: 'unset',
                        borderRadius: '50%',
                        p: 1,
                        width: 40,
                        height: 40
                    }}
                >
                    <SendIcon fontSize="small" />
                </Button>
            </Box>
        </Paper>
    );
};

export default AdminChat; 