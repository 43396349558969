export const DEFALT_OPTIONS = {
  gender: [
    {
      value: "Nam",
      name: "Nam",
    },
    {
      value: "Nữ",
      name: "Nữ",
    },
  ],
  loaive: [
    {
      value: "Vip",
      name: "Vip",
    },
    {
      value: "Platinum",
      name: "Platinum",
    },
    {
      value: "General",
      name: "General",
    },
  ],
  combo: [
    {
      value: "Vip Combo",
      name: "Vip Combo",
    },
    {
      value: "Platinum Combo",
      name: "Platinum Combo",
    },
    {
      value: "General Combo",
      name: "General Combo",
    },
  ],
  tunghoc: [
    {
      value: "The Restart",
      name: "restart",
    },
    {
      value: "Khóa Online",
      name: "online",
    },
  ],
  role: [
    {
      value: "admin",
      name: "Admin",
    },
    {
      value: "client",
      name: "Client",
    },
  ],
};
