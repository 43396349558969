const cron = require("cron");
const https = require("https");

const API_ENDPOINT =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BACKEND_URL
    : process.env.REACT_APP_BACKEND_URL_ONLINE;
const backendUrl = API_ENDPOINT;

const job = new cron.CronJob("*/14 * * * *", () => {
  // this function will be executed every 14 minutes
  // console.log("restarting server");

  //   Performan an HTTPS GET request to hit any backend api
  https
    .get(backendUrl, (res) => {
      if (res.statusCode === 200) {
        // console.log("Server restarted");
      } else {
        console.error(`failed to restart server with code: ${res.statusCode}`);
      }
    })
    .on("error", (err) => {
      console.error("error during Restart", err.message);
    });
});

module.exports = { job: job };
