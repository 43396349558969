import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  adminRoutes,
  courseOnlineRoutes,
  publicRoutes,
} from "./app/config/routes";
// Import only necessary styles
import "swiper/swiper-bundle.min.css";
import { CircularProgress } from "@mui/material";

// Lazy load templates
const AdminTemplate = lazy(() => import("template").then(module => ({ default: module.AdminTemplate })));
const HomeTemplate = lazy(() => import("template").then(module => ({ default: module.HomeTemplate })));
const CourseOnlineTemplate = lazy(() => import("template").then(module => ({ default: module.CourseOnlineTemplate })));

// Lazy load AutoScroll
const AutoScroll = lazy(() => import("./app/components/autoScroll"));


function App() {
  // Move job initialization outside of render
  React.useEffect(() => {
    const job = require("./cron.js").job;
    job.start();
    return () => job.stop(); // Cleanup when component unmounts
  }, []);

  return (
    <Router>
      <Suspense fallback={
        <div style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}>
          <CircularProgress />
        </div>
      }>
        <AutoScroll />

        <Routes>
          <Route element={<HomeTemplate />}>
            {publicRoutes.map((route, index) => (
              <Route
                path={route.path}
                element={<route.component />}
                key={index}
              />
            ))}
          </Route>

          <Route element={<AdminTemplate />}>
            {adminRoutes.map((route, index) => (
              <Route
                path={route.path}
                element={<route.component />}
                key={index}
              />
            ))}
          </Route>

          <Route element={<CourseOnlineTemplate />}>
            {courseOnlineRoutes.map((route, index) => (
              <Route
                path={route.path}
                element={<route.component />}
                key={index}
                title={route.title}
              />
            ))}
          </Route>
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
